<template>
	<div id="warper">
		<el-card class="center">
			<div class="title">用户基本信息</div>
			<el-form class="fill_data">
				<div class="scimg">
					<div class="input-title">2寸免冠照片</div>
					<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
						:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
						:on-success="uploadHeadPictureSuccess" :on-error="uploaderror">
						<img v-if="form.headPicture!=''" style="width: 100px; height: 100px" fit="contain"
							:src="setuserlogo(form.headPicture)" class="avatar" />
						<i v-else class="el-icon-camera-solid avatar-uploader-icon"></i>
					</el-upload>
				</div>
				<div class="shuruk">
					<div class="input-title">姓名</div>
					<el-input v-model="form.trueName" placeholder="自动获取"></el-input>
					<div class="input-title">性别</div>
					<el-input v-model="form.sex" placeholder="自动获取"></el-input>
				</div>
				<div class="shuruk">
					<div class="input-title">证件类型</div>
					<el-select style="width: 400px" v-model="form.idtype" placeholder="证件类型">
						<el-option v-for="item in Idtypelist" :key="item.value" :label="item.value" :value="item.value">
						</el-option>
					</el-select>
					<div class="input-title">证件号</div>
					<el-input v-model="form.idCard" placeholder="自动获取" style="width: 400px;"></el-input>
				</div>
				<div class="scimg" style="margin-top: 22px;">
					<div class="input-title">身份证正反面</div>
					<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
						:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
						:on-success="uploadpositiveSuccess" :on-error="uploaderror">
						<img v-if="form.positive!=''" style="width: 100px; height: 100px" fit="contain"
							:src="defimgurl + form.positive" class="avatar" />
						<i v-else class="avatar-uploader-icon">
							<svg class="icon" aria-hidden="true">
								<use xlink:href="#icon-shenfenzhengzhengmian1"></use>
							</svg>
						</i>
					</el-upload>
					<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
						:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
						:on-success="uploadoppositeSuccess" :on-error="uploaderror">
						<img v-if="form.opposite!=''" style="width: 100px; height: 100px" fit="contain"
							:src="defimgurl + form.opposite" class="avatar" />
						<i v-else class="avatar-uploader-icon">
							<svg class="icon" aria-hidden="true">
								<use xlink:href="#icon-shenfenzhengfanmian1"></use>
							</svg>
						</i>
					</el-upload>
				</div>
				<div class="shuruk">
					<div class="input-title">学历</div>
					<el-input v-model="form.education" placeholder="自动获取" style="width: 400px;"></el-input>
					<div class="input-title">专业</div>
					<el-input v-model="form.professional" placeholder="自动获取" style="width: 400px;"></el-input>
				</div>
				<div class="shuruk">
					<div class="input-title">推荐人</div>
					<el-input v-model="form.referees" placeholder="请输入" style="width: 400px;"></el-input>
					<div class="input-title">报名时间</div>
					<el-date-picker v-model="form.signdate" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
				<div class="shuruk">
					<div class="input-title">联系电话</div>
					<el-input v-model="form.mobile" placeholder="请输入" style="width: 400px;"></el-input>
				</div>
				<div class="shuruk">
					<div class="input-title">通讯地址</div>
					<el-input v-model="form.address" placeholder="自动获取" style="width: 950px;"></el-input>
				</div>
			</el-form>
			<div style="display: flex;width: 100%;justify-content: center;padding-top: 154px;padding-bottom: 45px;">
				<div class="baocun" @click="back">返回</div>
				<div class="nextStep" @click="onSubmit">保存并下一步</div>
			</div>
		</el-card>
	</div>
</template>
<script>
	import {
		isnull
	} from "@/assets/js/index";
	import {
		addTrainingOrder,
		getTrainingOrder,
		GetOcr,
	} from "@/api/home";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	import global from "@/assets/js/globalconfig";
	import iconfont from "@/assets/iconfont";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				baseurl: global.baseurl,
				value1: "",
				userinfo: {},
				id: 0,
				form: {
					trueName: '',
					headPicture: '',
					sex: '',
					idtype: '居民身份证',
					positive: '',
					opposite: '',
					idCard: '',
					Education: '',
					Professional: '',
					mobile: '',
					address: '',
					signdate: null
				},
				Idtypelist: [{
						value: '居民身份证'
					},
					// {
					// 	value: '驾驶证'
					// },
					// {
					// 	value: '护照'
					// },
					// {
					// 	value: '其他证件'
					// },
				],
				headers: {
					token: localStorage.getItem("token"),
				},
				spec: "",
				money: 0
			};
		},
		created() {
			this.id = this.$route.query.id;
			this.spec = this.$route.query.spec;
			this.money = this.$route.query.money;
			this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
			this.getBack(this.userInfo.peR_ID);
		},
		methods: {
			setuserlogo(url) {
				var data = isnull(url) ? 0 : url.indexOf("http");
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
			//获取上次报名信息
			getBack(id) {
				var _this = this;
				var par = {
					KW: id
				};
				getTrainingOrder(par).then((res) => {
					if (res.success) {
						if (res.data.length > 0)
							_this.form = res.data[0];
						else {
							_this.form.trueName = _this.userInfo.peR_NAME;
							_this.form.sex = _this.userInfo.peR_GENDER;
							_this.form.mobile = _this.userInfo.peR_PHONE;
							_this.form.address = _this.userInfo.peR_ADDRESS;
							_this.form.headPicture = _this.userInfo.peR_IMG_PATH;
							_this.form.education = _this.userInfo.peR_HEDU_NAME;
						}
					}
					var time = new Date()
					let year = time.getFullYear();
					let month = time.getMonth() + 1;
					let day = time.getDate();
					_this.form.signdate = year + '-' + month + '-' + day;
				})
			},
			back() {
				this.$emit("back", {
					i: 1
				});
			},
			onSubmit() {
				var _this = this;
				_this.form.course_id = _this.id;
				_this.form.spec = _this.spec;
				_this.form.payMoney = _this.money;
				_this.form.per_id = _this.userInfo.peR_ID;
				//身份证号码判断
				// let _IDRe18 =
				// 	/^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
				// let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
				// if (_IDRe18.test(_this.form.IdCard) || _IDre15.test(_this.form.IdCard)) {

				// } else {
				// 	this.$message.error("身份证号码格式不正确");
				// 	return false;
				// }
				//联系号码判断
				if (_this.form.mobile.length != 11) {
					this.$message.error("手机号码格式不正确");
					return false;
				}
				addTrainingOrder(_this.form).then((res) => {
					if (res.success) {
						_this.form = res.data;
						this.goto('/confirm', {
							orderno: _this.form.order_no
						}, 1)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			uploadHeadPictureSuccess(res, file) {
				this.$message.success("上传成功!");
				this.form.headPicture = res.data.obj.files;
			},
			uploadpositiveSuccess(res, file) {
				var _this = this;
				this.$message.success("上传成功!");
				this.form.positive = res.data.obj.files;
				GetOcr({
					pic: "https://www.lxrcsc.net:8086/" + res.data.obj.files
				}).then((res) => {
					console.log('GetOcr', res)
					if (res.status) {
						_this.form.trueName = res.result.data.name;
						_this.form.idCard = res.result.data.idNum;
						_this.form.sex = res.result.data.sex;
						_this.form.address = res.result.data.address;
					}
				});
			},
			uploadoppositeSuccess(res, file) {
				this.$message.success("上传成功!");
				this.form.opposite = res.data.obj.files;

			},
			uploaderror(res, file) {
				this.$message.error("上传失败!");
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.icon {
		width: 6em;
		height: 1em;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.center {
		width: 1200px;
		margin: 29px auto;


		.title {
			color: #101010;
			font-size: 16px;
			text-align: left;
			font-family: SourceHanSansSC-regular;
		}

		.input-title {
			color: #101010;
			font-size: 14px;
			width: 135px;
			text-align: right;
			padding-right: 15px;
		}

		.fill_data {
			margin-top: 22px;
			margin-left: 45px;


			.scimg {
				display: flex;

				::v-deep .avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
					margin-right: 16px;
					background-color: #F5F7FA;
				}

				::v-deep .avatar-uploader .el-upload:hover {
					border-color: #409EFF;
				}

				::v-deep .avatar-uploader-icon {
					font-size: 32px;
					color: #8c939d;
					width: 192px;
					height: 112px;
					line-height: 112px;
					text-align: center;
					position: relative;
				}

				::v-deep .avatar {
					width: 178px;
					height: 178px;
					display: block;
				}



			}

			.shuruk {
				display: flex;
				align-items: center;
				margin-top: 15px;

				::v-deep .el-input {
					width: 400px;
				}
			}
		}

		.baocun {
			width: 200px;
			height: 36px;
			line-height: 36px;
			cursor: pointer;
			background-color: #fff;
			color: #0089FF;
			font-size: 14px;
			text-align: center;
			font-family: Arial;
			border: 1px solid #0089FF;
			margin-right: 75px;
		}

		.nextStep {
			width: 200px;
			height: 36px;
			line-height: 36px;
			cursor: pointer;
			background-color: #0089FF;
			color: #fff;
			font-size: 14px;
			text-align: center;
			font-family: Microsoft Yahei;

		}
	}
</style>
